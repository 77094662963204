import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { BasicForm } from "@components/shared";
import { currencyValidation } from "@util/validation";
import { dollarsToPennies } from "@util/formatters";

export default function LootItemForm({
  id = null,
  name = "",
  description = "",
  retailValue = "",
  onSuccess = () => {},
  orgId,
  prizeId,
  locationId,
}) {
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    setLoading(true);

    const formatted = {
      ...formData,
      value_in_pennies: dollarsToPennies(
        !id && retailValue ? retailValue : formData.retail_value
      ),
    };

    if (id) return onUpdate(formatted);

    return onCreate(formatted);
  }

  function onCreate(formData) {
    const req = { ...formData };

    if (locationId) req.location_id = locationId;
    if (orgId) req.organization_id = orgId;
    // if (prizeId) req.prize_id = prizeId;
    if (prizeId) {
      req.prize_id = prizeId;
      // req.quantity = 100;
      req.quantity = parseInt(formData.quantity);
    }

    const url = prizeId ? `/add-loot-item-to-prize/` : `/loot-locker/`;

    axios
      .post(url, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Created!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      id,
    };

    axios
      .put(`/loot-items/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "name",
      label: "Name",
      initialValue: name,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "description",
      label: "Description (255 maximum characters)",
      initialValue: description,
      textarea: true,
      required: false,
      schema: () => Yup.string().max(255, "Maximum of 255 characters"),
    },
    {
      name: "retail_value",
      label: "Estimated Retail Value (xx.xx)",
      initialValue: retailValue,
      required: true,
      schema: () =>
        Yup.string()
          .matches(currencyValidation, "Not a valid amount")
          .required("Required"),
    },
    {
      name: "quantity",
      label: "Quantity",
      initialValue: "",
      required: true,
      schema: () =>
        Yup.string()
          .matches(/^[1-9]\d*$/, "Must be greater than zero")
          .required("Required"),
    },
  ]
    .filter((f) => (!id && !prizeId ? !["description"].includes(f.name) : true))
    .filter((f) => (!prizeId ? !["quantity"].includes(f.name) : true));

  return (
    <BasicForm
      loading={loading}
      onSubmit={onSubmit}
      fields={fields}
      buttonProps={{
        fullWidth: id ? false : true,
        size: prizeId ? "lg" : "sm",
      }}
    />
  );
}
