import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { BasicForm } from "@components/shared";
import countries from "@lib/countries";
import usStates from "@lib/usStates";
import timezones from "@lib/timezones";
import { initialState as initialLocationSettings } from "@components/Location/LocationSettings/helpers";
import { defaultSettings as initialSiteSettings } from "@components/shared/EntitySettingsCode";
import { locationVariety } from "@components/Location/helpers";

export default function LocationForm({
  address1 = "",
  address2 = "",
  allowCampaignCreate = false,
  callLetters = "",
  city = "",
  country = "",
  feedLoaderFilename = "",
  frequency = "",
  genre = "",
  id = null,
  name = "",
  onSuccess = () => {},
  organizationId,
  otpDisplayName = "",
  state = "",
  thirdPartyIdent = "",
  textOnly,
  timezone = "",
  variety = "",
  zip = "",
}) {
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const locationId = useSelector((state) =>
    state.manager ? state.manager.location_id : null
  );

  const isManager = useSelector((state) => state.manager) ? true : false;

  useEffect(() => {
    setOrgs([organizationId]);
  }, [organizationId]);

  function onSubmit(formData) {
    setLoading(true);

    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      settings: initialLocationSettings,
      site_settings: initialSiteSettings,
      otp_display_name: formData.name.replace(/[0-9]/g, ""),
      ...formData,
    };

    axios
      .post(`/locations/`, req)
      .then(({ data }) => {
        toast.success("Created");
        setLoading(false);
        navigate(`/locations/${data.response[0].id}`);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      location_id: id,
      state_province: formData.state,
    };

    axios
      .put(`/locations/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "name",
      label: "Name",
      initialValue: name,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "otp_display_name",
      label: "OTP Display Name (No numbers)",
      initialValue: otpDisplayName,
      required: true,
      schema: () =>
        Yup.string()
          .matches(/^([^0-9]*)$/, "No numbers")
          .required("Required"),
    },
    {
      name: "address_1",
      label: "Address",
      initialValue: address1,
      addressLookup: true,
      required: true,
      schema: () => Yup.string().required("Required!"),
    },
    {
      name: "address_2",
      label: "Address Line 2 (Ste, Apt, etc.)",
      initialValue: address2,
      schema: () => null,
    },
    {
      name: "city",
      label: "City",
      initialValue: city,
      required: true,
      schema: () => Yup.string().required("Required!"),
    },
    {
      name: "state",
      label: "State/Province",
      initialValue: state,
      select: true,
      options: usStates,
      required: true,
      schema: () => Yup.string().nullable().required("Required!"),
    },
    {
      name: "zip",
      label: "Zip/Postal Code",
      initialValue: zip,
      required: true,
      schema: () => Yup.string().required("Required!"),
    },
    {
      name: "country",
      label: "Country",
      initialValue: country,
      select: true,
      options: countries,
      required: true,
      schema: () => Yup.string().nullable().required("Required!"),
    },
    {
      name: "variety",
      label: "Variety",
      initialValue: variety,
      select: true,
      options: [
        { label: "Dealer", value: "2" },
        { label: "Station", value: "1" },
        { label: "Retail", value: "3" },
      ],
      required: true,
      schema: () => Yup.string().nullable().required("Required!"),
    },
    {
      name: "call_letters",
      label: "Call Letters",
      initialValue: callLetters,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "frequency",
      label: "Frequency",
      initialValue: frequency,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "genre",
      label: "Format",
      initialValue: genre,
      select: true,
      options: genreOptions,
      required: true,
      schema: () => Yup.string().nullable().required("Required!"),
    },
    {
      name: "feed_loader_filename",
      label: "Feed Loader Filename",
      initialValue: feedLoaderFilename,
      required: false,
      schema: () => Yup.string(),
    },
    {
      name: "third_party_ident",
      label: "Dealer #",
      initialValue: thirdPartyIdent,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "timezone",
      initialValue: timezone,
      required: true,
      select: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "Timezone",
      options: timezones,
    },
    {
      name: "allow_campaign_create",
      label: "Allow Campaign Create",
      initialValue: allowCampaignCreate,
      required: false,
      schema: () => null,
      toggle: true,
    },
    {
      name: "text_only",
      label: "Text Only",
      initialValue: textOnly,
      required: false,
      schema: () => null,
      toggle: true,
    },
  ]
    .filter((f) =>
      variety === locationVariety.station
        ? !["third_party_ident"].includes(f.name)
        : true
    )
    .filter((f) =>
      variety !== locationVariety.station
        ? ![
            "call_letters",
            "frequency",
            "genre",
            "feed_loader_filename",
          ].includes(f.name)
        : true
    )
    .filter((f) =>
      variety !== locationVariety.dealer
        ? !["third_party_ident"].includes(f.name)
        : true
    )
    .filter((f) =>
      !id ? ["name", "variety", "timezone"].includes(f.name) : true
    )
    .filter((f) =>
      isManager
        ? ![
            "allow_campaign_create",
            "text_only",
            "feed_loader_filename",
          ].includes(f.name)
        : true
    )
    .filter((f) =>
      locationId ? !["status", "variety"].includes(f.name) : true
    );

  return (
    <React.Fragment>
      {(orgs.length > 0 || id) && !success && (
        <BasicForm
          buttonProps={{
            fullWidth: !id ? true : false,
          }}
          loading={loading}
          onSubmit={onSubmit}
          fields={fields}
        />
      )}
    </React.Fragment>
  );
}

const genreOptions = [
  { label: "AAA", value: 1 },
  { label: "AC", value: 2 },
  { label: "Active Rock", value: 3 },
  { label: "Alternative", value: 4 },
  { label: "CHR", value: 5 },
  { label: "Classic Alternative", value: 6 },
  { label: "Classic Hits", value: 7 },
  { label: "Classic Rock", value: 8 },
  { label: "Country", value: 9 },
  { label: "Hot AC", value: 10 },
  { label: "Rhythmic Top 40", value: 11 },
  { label: "Rock", value: 12 },
  { label: "Sports", value: 13 },
  { label: "Talk", value: 14 },
  { label: "Urban AC", value: 15 },
  { label: "Variety", value: 16 },
  { label: "80's", value: 17 },
].map((m) => ({
  ...m,
  value: `${m.value}`,
}));
