import * as Yup from "yup";

import usStates from "@lib/usStates";

export const fieldLib = [
  {
    name: "first_name",
    label: "First Name",
    schema: Yup.string(),
  },
  {
    name: "last_name",
    label: "Last Name",
    schema: Yup.string(),
  },
  {
    name: "state",
    label: "State",
    schema: Yup.string(),
    select: true,
    options: usStates,
  },
  {
    name: "email",
    label: "Email",
    schema: Yup.string().email(),
  },
];

export const fieldTemplates = [
  {
    name: "textarea",
    schema: Yup.string(),
    textarea: true,
  },
  {
    name: "select",
    select: true,
    schema: Yup.string().nullable(""),
  },
  {
    name: "radio",
    schema: Yup.string(),
    radio: true,
  },
  {
    name: "text",
    schema: Yup.string(),
  },
  {
    name: "checkbox",
    schema: Yup.string(),
  },
  {
    name: "image",
    file: {
      clearable: true,
      accept: "image/png, image/jpeg",
      placeholder: "Upload file (max file size 10 MB)",
    },
    schema: Yup.string().nullable(),
  },
  {
    name: "date",
    schema: Yup.string(),
  },
];
