import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useParams,
  useSearchParams,
  useNavigate,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import {
  Card,
  Flex,
  ThemeIcon,
  Anchor,
  Button,
  Text,
  Grid,
  Space,
  Switch,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IconCopy } from "@tabler/icons-react";

import {
  ErrorView,
  MicrositeSettings,
  PageTitle,
  Subnav,
} from "@components/shared";
import {
  ContestAlerts,
  ContestAssets,
  ContestFinalists,
  ContestForm,
  ContestKeywordEffortAssociate,
  ContestKeywordManagement,
  ContestParticipants,
  ContestReadOnly,
  ContestReplicate,
  ContestSettings,
  ContestStatus,
  ContestWizardStatus,
} from "@components/Contest";
import { TagManagement } from "@components/Tag";
import { PrizeManagement } from "@components/Prize";
import { EntityEmailTemplates } from "@components/EmailServer";
import EffortBuilder from "@components/EffortBuilder";
import { LocationAssociate } from "@components/Location";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";
import { penniesToDollars } from "@util/formatters";

export default function ContestDetail() {
  // const [info, setInfo] = useState(null);
  // const [error, setError] = useState(null);
  const [showWizard, setShowWizard] = useState(true);
  const params = useParams();
  const { id } = params;
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const queryKey = `contests${id}`;

  const navigate = useNavigate();

  const managerInfo = useSelector((state) => state.manager);
  const isManager = managerInfo ? true : false;

  useEffect(() => {
    // setInfo(null);
    fetchData();
  }, [id]);

  const fetchData = () => queryClient.invalidateQueries([queryKey]);

  const { failureReason: error, data: info } = useQuery({
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .get(`/contests/${id}/`)
        .then(({ data }) => {
          const res = data.response[0];

          if (res.variety === 2) {
            navigate(`/prize-pools/${id}`);
          }

          if (res.variety === 3) {
            navigate(`/auctions/${id}`);
          }

          // setInfo(res);
          if (JSON.stringify(res.wizard_settings) === "{}") {
            setShowWizard(false);
          }

          if (
            searchParams.get("showWizard") === "false" ||
            res.for_duplication
          ) {
            setShowWizard(false);
          }

          return data.response[0];
        })
        .catch((err) => {
          throw err;
        }),
  });

  const linkKeyword =
    searchParams.get("linkKeyword") &&
    searchParams.get("linkKeyword") === "true";

  const searchParamsEffortName = searchParams.get("effortName");

  // function fetchData() {
  //   axios
  //     .get(`/contests/${params.id}/`)
  //     .then(({ data }) => {
  //       const res = data.response[0];

  //       if (res.variety === 2) {
  //         navigate(`/prize-pools/${params.id}`);
  //       }

  //       if (res.variety === 3) {
  //         navigate(`/auctions/${params.id}`);
  //       }

  //       setInfo(res);
  //       if (JSON.stringify(res.wizard_settings) === "{}") {
  //         setShowWizard(false);
  //       }

  //       if (searchParams.get("showWizard") === "false") {
  //         setShowWizard(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setInfo(null);
  //       setError(err);
  //     });
  // }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const subtitles = [
    {
      value: info.created_in_campaign_effort_id,
      icon: entityIcon.effort(),
      color: entityColor.effort,
      name: info.created_in_name,
      url: `/efforts`,
    },
    {
      value: info.organization_id,
      icon: entityIcon.organization(),
      color: entityColor.organization,
      name: info.organization_name,
      url: `/organizations`,
    },
    {
      value: info.location_id,
      icon: entityIcon.location(),
      color: entityColor.location,
      name: info.location_name,
      url: `/locations`,
    },
  ]
    .filter((f) => f.value)
    .map((m) => ({
      ...m,
      url: `${m.url}/${m.value}`,
    }));
  const { wizard_settings: wizardSettings, wizard_status: wizardStatus } = info;
  const wizardComplete = wizardStatus.wizard_complete;
  const showLocations =
    (!managerInfo ||
      (managerInfo && managerInfo.organization_id) ||
      info.organization_id) &&
    info.created_in_campaign_effort_id;
  const hasWizardSettings = JSON.stringify(wizardSettings) !== "{}";
  const multiPossible = !info.created_in_campaign_effort_id;
  const isForDuplication = info.for_duplication;
  const showKeywords = isForDuplication
    ? false
    : multiPossible
    ? true
    : wizardSettings.allow_keyword !== false;

  const ContestTitle = ({ showLink }) => (
    <React.Fragment>
      <PageTitle
        title={info.name}
        subtitle={<React.Fragment>Contest</React.Fragment>}
        icon={entityIcon.contest()}
        iconColor={entityColor.contest}
        style={{ marginBottom: 0 }}
      />
      {info.edit && (
        <React.Fragment>
          {subtitles.map((m, i) => (
            <PageTitle
              key={i}
              icon={m.icon}
              iconColor={m.color}
              style={{ marginBottom: 0 }}
              subtitle={
                <Anchor
                  style={{ color: "inherit" }}
                  component={Link}
                  to={m.url}
                  target="_blank"
                >
                  {m.name}
                </Anchor>
              }
            />
          ))}
        </React.Fragment>
      )}
      {isForDuplication && (
        <PageTitle
          icon={<IconCopy />}
          iconColor="gray"
          subtitle="For duplication purposes only"
        />
      )}
      <Space mt="lg" />
    </React.Fragment>
  );

  const WizardToggle = () => (
    <Switch
      label="Quick Settings"
      checked={showWizard}
      onChange={() => setShowWizard(!showWizard)}
      mb="lg"
    />
  );

  if (!info.edit) {
    return (
      <React.Fragment>
        <ContestTitle />
        <ContestReadOnly info={info} />
      </React.Fragment>
    );
  }

  if (!wizardComplete || showWizard) {
    return (
      <div>
        <ContestTitle />
        {wizardComplete && hasWizardSettings && <WizardToggle />}
        {wizardComplete && (
          <ContestAlerts
            contestId={id}
            setShowWizard={(e) => setShowWizard(e)}
          />
        )}
        <Space mt="lg" />
        <ContestWizardStatus
          fetchData={fetchData}
          effortId={
            info.created_in_campaign_effort_id || info.campaign_effort_id
          }
          id={id}
          locationId={info.location_id}
          organizationId={info.organization_id}
          status={info.status}
          wizardComplete={wizardComplete}
          wizardSettings={wizardSettings}
          isForDuplication={isForDuplication}
          wizardStatus={wizardStatus}
          contestDates={
            info.start_date && info.end_date
              ? {
                  start_date: info.start_date,
                  start_time: info.start_time,
                  start_date_formatted: info.start_date_formatted,
                  end_date: info.end_date,
                  end_date_formatted: info.end_date_formatted,
                  end_time: info.end_time,
                }
              : null
          }
        />
        {wizardComplete && multiPossible && !isForDuplication && (
          <>
            {searchParams.get("effortId") && (
              <Card mb="sm">
                {linkKeyword ? (
                  <>
                    <Text fw={600}>Link a keyword</Text>
                    <Text>
                      You're here to link a keyword to{" "}
                      <b>{searchParamsEffortName}</b>
                    </Text>
                  </>
                ) : (
                  <>
                    <Text fw={600}>You're done here!</Text>
                    <Text>You can now go back to effort setup</Text>
                  </>
                )}
                {searchParamsEffortName ? (
                  <Button
                    mt="sm"
                    color="green"
                    style={{ width: "fit-content" }}
                    size="xs"
                    component="a"
                    href={`/efforts/${searchParams.get("effortId")}`}
                    target="_blank"
                  >
                    View Effort
                  </Button>
                ) : (
                  <Button
                    mt="sm"
                    color="green"
                    style={{ width: "fit-content" }}
                    size="xs"
                    onClick={() =>
                      navigate(`/efforts/${searchParams.get("effortId")}`)
                    }
                  >
                    Take me there
                  </Button>
                )}
                {/* <Button
                  mt="sm"
                  color="green"
                  style={{ width: "fit-content" }}
                  size="xs"
                  onClick={() => {
                    searchParamsEffortName
                      ? window.open(
                          `/efforts/${searchParams.get("effortId")}`,
                          "_blank",
                          "rel=noopener noreferrer"
                        )
                      : navigate(`/efforts/${searchParams.get("effortId")}`);
                  }}
                >
                  {linkKeyword ? "View Effort" : "Take me there"}
                </Button> */}
              </Card>
            )}
            <Card mb="sm">
              <Text fw={600} mb="xs">
                Keywords
              </Text>
              {searchParamsEffortName && (
                <Text>
                  Select an existing <b>{searchParamsEffortName}</b> keyword
                  below or create a new one
                </Text>
              )}
              <ContestKeywordEffortAssociate
                addOutside
                contestId={id}
                locationId={info.location_id}
                organizationId={info.organization_id}
              />
              {/* <Text fw={600}>Link outside effort</Text>
              <Text>
                Trying to link to an effort that you don't control? That's easy
                to do now!
              </Text>
              <Button
                mt="sm"
                style={{ width: "fit-content" }}
                size="xs"
                onClick={() => {
                  setShowWizard(false);
                  navigate(`/contests/${id}/link-efforts`);
                }}
              >
                Take me there
              </Button> */}
            </Card>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <ContestTitle showLink />
      {wizardComplete && hasWizardSettings && !isForDuplication && (
        <WizardToggle />
      )}
      <Space mt="lg" />
      <Flex>
        <Subnav
          buttonColor={entityColor.contest}
          links={[
            { to: `/contests/${id}`, text: "Detail" },
            { to: `/contests/${id}/builder`, text: "Builder" },
            { to: `/contests/${id}/prizes`, text: "Prizes" },
            { to: `/contests/${id}/keywords`, text: "Keywords" },
            { to: `/contests/${id}/settings`, text: "Settings" },
            { to: `/contests/${id}/assets`, text: "Image" },
            { to: `/contests/${id}/tags`, text: "Tags" },
            { to: `/contests/${id}/participants`, text: "Participants" },
            { to: `/contests/${id}/finalists`, text: "Finalists" },
            { to: `/contests/${id}/email`, text: "Email" },
            { to: `/contests/${id}/replication`, text: "Replication" },
            // {
            //   to: `/contests/${id}/link-efforts`,
            //   text: "Link Efforts",
            // },
            // { to: `/contests/${id}/locations`, text: "Locations" },
            // { to: `/contests/${id}/config`, text: "Look & Feel" },
          ]
            // .filter((f) =>
            //   info.created_in_campaign_effort_id
            //     ? f.text !== "Link Efforts"
            //     : true
            // )
            .filter((f) =>
              !info.created_in_campaign_effort_id ? f.text !== "Builder" : true
            )
            .filter((f) =>
              // wizardSettings.allow_keyword === false
              !showKeywords ? f.text !== "Keywords" : true
            )
            .filter((f) =>
              info.location_id || hasWizardSettings
                ? !["Participants"].includes(f.text)
                : true
            )
            .filter((f) =>
              wizardSettings.show_finalists === false
                ? f.text !== "Finalists"
                : true
            )
            .filter((f) => (!showLocations ? f.text !== "Locations" : true))
            .filter((f) =>
              isManager ? !["Replication"].includes(f.text) : true
            )
            .filter((f) =>
              isForDuplication
                ? !["Participants", "Keywords", "Finalists"].includes(f.text)
                : true
            )
            .map((m) => ({
              ...m,
              to: `${m.to}${location.search}`,
            }))}
        />
        {info.created_in_campaign_effort_id && info.organization_id && (
          <Button
            component={Link}
            variant="subtle"
            color="gray"
            size="xs"
            target="_blank"
            to={`/efforts/${info.created_in_campaign_effort_id}/locations`}
          >
            Locations
          </Button>
        )}
      </Flex>
      <Space mt="lg" />
      {wizardComplete && (
        <ContestAlerts contestId={id} setShowWizard={(e) => setShowWizard(e)} />
      )}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
              <Grid>
                <Grid.Col span={{ base: 12, md: 9 }}>
                  <Card>
                    <ContestForm
                      allowLocationAccess={info.allow_location_access}
                      contactName={info.contact_name}
                      contactNumber={info.contact_number}
                      contactEmail={info.contact_email}
                      endDate={info.end_date}
                      endTime={info.end_time}
                      id={id}
                      internalName={info.internal_name}
                      isForDuplication={isForDuplication}
                      isManager={isManager}
                      name={info.name}
                      onSuccess={fetchData}
                      pickupDate={info.pickup_date}
                      pickupTime={info.pickup_time}
                      pickupTimezone={info.pickup_timezone}
                      retailValue={
                        info.retail_value_in_pennies
                          ? penniesToDollars(info.retail_value_in_pennies)
                          : ""
                      }
                      startDate={info.start_date}
                      startTime={info.start_time}
                      timezone={info.timezone}
                    />
                  </Card>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 3 }}>
                  <ContestStatus
                    contestId={id}
                    status={info.status}
                    fetchData={fetchData}
                    liveStatus={info.live_status}
                  />
                </Grid.Col>
              </Grid>
            </React.Fragment>
          }
        />
        <Route
          path="prizes"
          element={
            <React.Fragment>
              <PrizeManagement
                organizationId={info.organization_id}
                locationId={info.location_id}
                contestId={id}
                fetchData={fetchData}
                contestForDuplication={isForDuplication}
              />
            </React.Fragment>
          }
        />
        {showKeywords && (
          <Route
            path="keywords"
            element={
              <React.Fragment>
                {multiPossible ? (
                  <Card>
                    <ContestKeywordEffortAssociate
                      addOutside
                      contestId={id}
                      locationId={info.location_id}
                      organizationId={info.organization_id}
                    />
                  </Card>
                ) : (
                  <ContestKeywordManagement contestId={id} hideTransfer />
                )}
              </React.Fragment>
            }
          />
        )}
        {!info.location_id && !hasWizardSettings && !isForDuplication && (
          <Route
            path="participants"
            element={
              <React.Fragment>
                <ContestParticipants
                  contestId={id}
                  orgId={info.organization_id}
                  locationId={info.location_id}
                />
              </React.Fragment>
            }
          />
        )}
        <Route
          path="settings"
          element={
            <Card>
              <ContestSettings
                beginDate={info.start_date}
                canEditWinnerRules={info.can_edit_winner_rules}
                contestId={id}
                contestInfo={info}
                endDate={info.end_date}
                fetchContest={fetchData}
                wizardSettings={wizardSettings}
              />
            </Card>
          }
        />
        <Route
          path="tags"
          element={
            <React.Fragment>
              <TagManagement contestId={id} />
            </React.Fragment>
          }
        />
        <Route
          path="assets"
          element={
            <React.Fragment>
              <ContestAssets
                contestId={id}
                contestInfo={info}
                fetchContest={fetchData}
              />
            </React.Fragment>
          }
        />
        {wizardSettings.show_finalists !== false && !isForDuplication && (
          <Route
            path="finalists"
            element={
              <React.Fragment>
                <ContestFinalists
                  contestId={id}
                  contestSettings={info.settings}
                  fetchData={fetchData}
                  totalEntrants={info.total_entrants}
                />
              </React.Fragment>
            }
          />
        )}
        <Route
          path="email"
          element={
            <React.Fragment>
              <EntityEmailTemplates
                postmarkTemplateNames={info.postmark_template_names}
                contestId={id}
                templates={[
                  {
                    name: "Winner Notification",
                    field_name: "winner-notification",
                    value: info.winner_template,
                  },
                ]}
                fetchData={fetchData}
              />
            </React.Fragment>
          }
        />
        <Route
          path="config"
          element={
            <Grid>
              <Grid.Col span={{ base: 12 }}>
                <div>
                  <MicrositeSettings
                    // campaignId={info.contest_id}
                    contestId={id}
                    locationId={info.location_id}
                    onUpdate={fetchData}
                  />
                </div>
              </Grid.Col>
            </Grid>
          }
        />
        {showLocations && (
          <Route
            exact
            path="locations"
            element={
              <React.Fragment>
                <LocationAssociate
                  effortId={info.created_in_campaign_effort_id}
                />
              </React.Fragment>
            }
          />
        )}
        {info.created_in_campaign_effort_id && (
          <Route
            path="builder"
            element={
              <EffortBuilder
                effortId={info.created_in_campaign_effort_id}
                fetchEffortData={() => true}
                showPublish
              />
            }
          />
        )}
        {!managerInfo && (
          <Route
            path="replication"
            element={
              <>
                <ContestReplicate
                  contestId={id}
                  onSuccess={() => navigate(`/contests/${id}`)}
                  disabled={!wizardComplete}
                />
              </>
            }
          />
        )}
      </Routes>
    </div>
  );
}
